import styled from 'styled-components';

// downshift requires the dropdown be present in the DOM even when it is not visible
export const Relative = styled.div<{ isOpen?: boolean }>`
  display: ${({ isOpen = false }) => (isOpen ? 'block' : 'none')};
  position: relative;
`;

export const Absolute = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
`;

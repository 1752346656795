export type TCarValuationPageNames =
  | PAGE.INSTANT_OFFER_VALUATION
  | PAGE.PRIVATE_VALUATION;

export enum PAGE {
  APP = 'APP',
  HOME = 'HOME',
  VIDEO_REVIEWS = 'VIDEO_REVIEWS',
  DASHBOARD = 'DASHBOARD',
  MESSAGES = 'MESSAGES',
  HUB = 'HUB',
  SEARCH = 'SEARCH',
  AD_DETAILS = 'AD_DETAILS',
  EDIT_AD = 'EDIT_AD',
  EDIT_AD_DONE = 'EDIT_AD_DONE',
  INSTANT_OFFER_VALUATION = 'instant-offer-valuation',
  PRIVATE_VALUATION = 'private-valuation',
  PUBLISH = 'place-ad',
  PUBLISH_C2B = 'place-ad-c2b',
  ORDER = 'order',
  CREATE_ORDER = 'create-order',
  DEALER_DETAILS = 'dealer-details',
  DEALERHUB_AD_DETAILS = 'dealerhub-ad-details',
  DEALERHUB_MY_OFFERS = 'dealerhub-my-offers',
  FIND_A_DEALER = 'find-a-dealer',
  UNSUBSCRIBE = 'unsubscribe',
  SIGNIN = 'signin',
  LOCAL_DEV_SIGNOUT = 'local-dev-signout',
  SITEMAP = 'SITEMAP',
  VEHICLE_LOOKUP = 'VEHICLE_LOOKUP',
  AUTHENTICATION = 'AUTHENTICATION',
  API = 'API',
  TEMP = 'TEMP',
  GSSP_WRAPPER = 'GSSP_WRAPPER',
  PRICE_ASSIST = 'PRICE_ASSIST',
  PROFILE = 'PROFILE',
  RATINGS_AND_REVIEWS = 'RATINGS_AND_REVIEWS',
}

export type PageKeys = `${PAGE}`;

export enum API {
  KEYCLOAK_SIGNOUT = 'keycloak_signout',
  MILEAGE_INDICATOR = 'MILEAGE_INDICATOR',
  HISTORY_CHECK_AD_DETAILS = 'HISTORY_CHECK_AD_DETAILS',
  HISTORY_CHECK_STANDALONE = 'HISTORY_CHECK_STANDALONE',
  NAVIGATION = 'NAVIGATION',
}
